@import 'variables';
@import 'mixins';
@import 'colors';

.custom-radio {
    .custom-control-input {
        top: 4px;
        left: 2px;
        &:focus {
            outline: none;
        }
    }
    .custom-control-label {
        color: rgba(0, 0, 0, 0.65);
        @include font(medium, 14);
        line-height: 23px;
        letter-spacing: -0.07px;
        cursor: pointer;
        padding-left: 4px;
    }
    .custom-control-label::before {
        width: 16px;
        height: 16px;
        border-radius: 250px;
        border: 1px solid rgba(0, 0, 0, 0.65);
        background-color: #fff;
        box-shadow: none !important;
    }

    .custom-control-label::after {
        top: 7px;
        left: -17px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
    .custom-control-input:checked ~ .custom-control-label {
        color: #141414;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #1eccb0 !important;
        background-color: #1eccb0 !important;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none;
        background-color: #08332c;
    }
}

@media only screen and (max-width: 1080px) {
    .custom-radio {
        .custom-control-label::after {
            // left: -1.2rem;
            left: -15px;
        }
    }
}
