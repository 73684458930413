@import '../../../../node_modules/bootstrap/scss/mixins';
@import 'colors';

// background colours
$ui-background-default: $neutral-0;
$ui-background-grey: $neutral-10;
$ui-container-default: $neutral-10;
$ui-container-grey: $neutral-0;

// primary brand colours
$primary-brand-color: $primary-120;
$primary-brand-color-dark: $primary-160;
$primary-brand-color-darkest: $primary-110;

$primary-brand-color-light-theme: $primary-120;
$primary-brand-text-color-light-theme: $primary-140;
$primary-brand-color-light-theme-hover: $primary-130;
$primary-brand-text-color-light-theme-hover: $neutral-0;

$primary-brand-color-dark-theme: $primary-160;
$primary-brand-text-color-dark-theme: $primary-150;
$primary-brand-color-dark-theme-hover: $primary-170;
$primary-brand-text-color-dark-theme-hover: $primary-150;

// secondary brand colours
$secondary-brand-color-light: $secondary-80;
$secondary-brand-color: $secondary-90;
$secondary-brand-color-dark: $secondary-100;
$secondary-brand-color-darkest: $secondary-110;

// text colours
$primary-text-color: $neutral-100;
$secondary-text-color: $neutral-80;
$secondary-text-color-light: $neutral-70;

$placeholder-color: $neutral-50;

$primary-link-color: $info-100;

$error-color: $negative-100;
$warning-color: $warning-100;
$success-color: $positive-100;

// divider colours
$divider-low-contrast-color: $neutral-10;
$divider-high-contrast-color: $neutral-20;

// disabled colours
$disabled-background-color: $neutral-20;
$disabled-text-color: $neutral-40;

// hover colours
$hover-background-color: $primary-100;

// primary font family
$primary-font-family: JioMedium, sans-serif;
$primary-font-size: 14px;
$primary-font-size-value: 14;

// bootstrap variables
$primary: $primary-90;
$theme-colors: (
    'primary': $primary-90
);
$aqua-deep: #09493e;
$aqua-spring: #e8faf7;
$black: #000000;
$bon-jour: #e0e0e0;
$bottle-green: #08332c;
$caribbean-green: #1eccaf;
$chicago: #5a5a5a;
$chicago-2: #59595980;
$clam-shell: #deadad;
$downy: #58dac5;
$eden: #0e5c4f;
$gargoyle-gas: #fed847;
$heavy-metal: #2b2b2b;
$illuminating-emerald: #348376;
$licorice: #141414;
$riptide: #8ae5d6;
$royal-blue: #4747f9;
$sonic-silver: #75757580;
$star-dust: #a1a1a1;
$steel-blue: #007fad;
$te-papa-green: #1a413b;
$white: #ffffff;
$white-2: #ffffffc7;
$white-ice: #def8f3;

$font-size-l: 18px;
$font-size-m: 16px;
$font-size-s: 14px;
$font-size-xl: 24px;
$font-size-xs: 12px;
$font-size-xxl: 40px;
$font-size-xxxl: 64px;

$font-family-jiotype-black: 'JioType-Black', Helvetica;
$font-family-jiotype-bold: 'JioType-Bold', Helvetica;
$font-family-jiotype-light: 'JioType-Light', Helvetica;
$font-family-jiotype-medium: 'JioType-Medium', Helvetica;
$font-family-webtype-black: 'webBlack', Helvetica;
$font-family-webtype-bold: 'webBold', Helvetica;
$font-family-webtype-medium: 'webMedium', Helvetica;
$font-family-webtype-light: 'webLight', Helvetica;

$white-opacity-78: rgba(255, 255, 255, 0.78);
