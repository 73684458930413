@import 'variables';
@import 'mixins';

.recurring-event-popup {
    &.p-overlaypanel {
        width: 230px !important;
        height: 200px !important;
        gap: 10px;
        border-radius: 8px;
        margin-top: 6px !important;
        background: #f5f5f5 !important;
        z-index: 1000;
        .overlay-header {
            font-family: JioMedium !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 20px !important;
            letter-spacing: -0.005em;
            text-align: left;
            display: flex;
            justify-content: space-between;
            border-radius: 8px;
            color: #0e5c4f !important;
            padding: 12px 20px 8px 20px;
            position: sticky;
            top: 0;
            background: #f5f5f5;
            z-index: 1;
        }
        .p-overlaypanel-contents {
            font-family: JioMedium !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 30px !important;
            letter-spacing: -0.005em;
            text-align: left;
            color: #141414;
            padding: 0px 20px 10px 20px !important;
            opacity: 1;
            overflow-y: scroll;
            height: 155px;
            margin-right: 4px;

            &.p-overlaypanel-contents::-webkit-scrollbar {
                width: 8px;
                opacity: 0px;
                background: none !important;
                box-shadow: none !important;
            }

            &.p-overlaypanel-contents::-webkit-scrollbar-thumb {
                width: 8px;
                height: 28px;
                top: 4px;
                left: 4px;
                border-radius: 10px;
                opacity: 0.15 !important;
                background: #d4d4d4;
                border: none !important;
            }

            &.p-overlaypanel-contents::-webkit-scrollbar-track {
                box-shadow: none !important;
            }
        }
    }
    @media (max-width: 1024px) {
        &.p-overlaypanel {
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            .p-overlaypanel-contents {
                &.p-overlaypanel-contents::-webkit-scrollbar {
                    width: 4px;
                    opacity: 0px;
                    background: none !important;
                    box-shadow: none !important;
                }

                &.p-overlaypanel-contents::-webkit-scrollbar-thumb {
                    width: 2px;
                    height: 56px;
                    top: 6px;
                    left: 4px;
                    border-radius: 10px;
                    opacity: 0.3 !important;
                    background: #d4d4d4;
                    border: none !important;
                }
            }
        }
    }
}

.p-calendar {
    width: 100%;
    .p-inputtext {
        cursor: pointer;
    }
    .p-button-icon-only {
        background: url(~src/assets/img/ic_calendar.svg) no-repeat center center;
        border: none;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.p-datepicker {
    background-color: #fff;
    padding: 0;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #959595;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    z-index: 9999 !important;
    .p-monthpicker {
        .p-monthpicker-month {
            color: #000 !important;
            &:hover {
                background: $primary-brand-color-dark !important;
                color: $primary-brand-text-color-dark-theme !important;
            }
        }
    }
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: 0 0 0 0.2rem $primary-brand-color-dark !important;
    }
    &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
        box-shadow: 0 0 0 0.1rem $primary-brand-color-dark-theme-hover !important;
        background-color: $primary-brand-color-dark !important;
    }
    &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow: 0 0 0 0.1rem $primary-brand-color-dark-theme-hover !important;
        background-color: $primary-brand-color-dark !important;
    }
    .p-yearpicker .p-yearpicker-year {
        color: $primary-brand-text-color-dark-theme !important;
        &:hover {
            background: $primary-brand-color-dark !important;
        }
        &.p-highlight {
            background: $primary-brand-color-dark !important;
        }
    }

    .p-datepicker-group-container {
        width: 100%;
        .p-datepicker-header {
            @include font(medium, 18);
            padding: 0.5rem;
            background-color: #fff;
            color: #000 !important;
            .p-datepicker-title {
                .p-datepicker-month {
                    &:hover {
                        color: #1eccb0 !important;
                    }
                }
                .p-datepicker-year {
                    &:hover {
                        color: #1eccb0 !important;
                    }
                }
            }
        }
        .p-datepicker-next,
        .p-datepicker-prev {
            color: #000 !important;
            &:focus {
                box-shadow: none !important;
            }
        }
        table {
            thead {
                tr {
                    th {
                        color: #000;
                        padding: 0.5rem 0;
                        @include font(medium, 15);
                        span {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 0;
                    &.p-datepicker-today {
                        span {
                            &:not(.p-highlight) {
                                background: #fff !important;
                                border-color: $primary-brand-color !important;
                                color: #1a413b !important;
                            }
                            background: $primary-brand-color !important;
                            color: #1a413b !important;
                        }
                    }
                    span {
                        @include font(medium, 14);
                        color: #000;
                        border-radius: 4px;
                        margin: 0.2rem;
                        &.p-highlight {
                            background: $primary-brand-color !important;
                            color: #1a413b !important;
                        }
                    }
                }
            }
        }
        &:not(.p-disabled) {
            table {
                td {
                    span:not(.p-highlight):not(.p-disabled):hover {
                        background: $primary-brand-color;
                        color: #1a413b !important;
                    }
                }
            }
        }
    }
}

.p-overlaypanel {
    &.chat-more-options {
        z-index: 10008 !important;
        border: none !important;
        margin-top: 0px !important;
        transform: translateY(0px) !important;
        background: #ffffff;
        color: #333333;
        border-radius: 8px !important;
        .p-overlaypanel-content {
            padding: 0.571rem 1rem !important;
        }
    }
    &.chat-more-options::before {
        content: none !important;
    }
    &.chat-more-options::after {
        content: none !important;
    }
}

p-confirmdialog {
    &.positioned-dialog {
        .p-dialog-mask {
            position: absolute;
        }
        .p-dialog .p-dialog-header .p-dialog-title {
            @include text(16px, #141414, 700, 24px);
        }
        .p-dialog .p-confirm-dialog-message {
            @include text(14px, rgba(0, 0, 0, 0.65), 500, 20px);
        }
        .p-dialog .p-dialog-footer {
            padding: 40px 32px 32px !important;
        }
    }
    .p-dialog-mask {
        z-index: 1141 !important;
    }
    .ui-confirmdialog {
        .ui-dialog-footer {
            p-footer {
                button {
                    height: 35px;
                    border-radius: 4px;
                    background: #fff !important;
                    color: $primary-brand-color-dark !important;
                    font-family: JioMedium !important;
                    outline: none !important;
                    .ui-button-text {
                        padding: 0 1rem !important;
                    }
                    &.blue {
                        background: #fff !important;
                        border: 1px solid #fff !important;
                        color: $primary-brand-color-dark !important;
                        font-family: JioMedium !important;
                        outline: none !important;
                    }
                }
            }
        }
        .p-confirm-dialog-message {
            margin-left: 0 !important;
        }
    }
    &.with-header {
        .ui-confirmdialog {
            .ui-dialog-titlebar {
                display: block !important;
                background-color: #fff !important;
            }
        }
    }

    .ui-confirmdialog {
        border-radius: 4px;
        overflow: hidden;
        .ui-dialog-titlebar {
            display: none !important;
        }
        .ui-dialog-content {
            border: none !important;
            padding-top: 1rem !important;
            .ui-confirmdialog-icon {
                display: none !important;
            }
        }
        .ui-dialog-footer {
            border: none !important;
            button {
                background: #fff !important;
                border: 1px solid #fff !important;
                color: $primary-brand-color-dark !important;
                font-family: JioMedium !important;
                outline: none !important;
                .ui-button-icon-left {
                    display: none !important;
                }
                .ui-button-text {
                    padding: 1rem;
                }
            }
        }
    }
    .p-confirm-dialog-message {
        .uplod-cancel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .cancelfile-header {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 22px;
            font-family: JioBold;
            color: #141414;
        }
        .cancel-txt {
            font-size: 18px;
            font-family: JioMedium;
            color: #000000a6;
        }
        .livechat-popup-header {
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 16px;
            font-family: JioBold;
            color: #141414;
        }
        .livechat-popup-txt {
            font-size: 14px;
            font-family: JioMedium;
            color: #000000a6;
        }
    }
}

p-table {
    .ui-table {
        height: 100%;
        overflow-y: auto;
        .ui-table-wrapper {
            height: 99%;
            table {
                max-height: 100%;
                thead {
                    tr {
                        th {
                            font-family: JioMedium !important;
                            color: #5b5c60 !important;
                            background: white !important;
                            border: 0 none !important;
                            border-bottom: 1px solid #d7d7d7 !important;
                        }
                    }
                }
                tbody {
                    tr {
                        font-family: JioMedium !important;
                        color: #5b5c60 !important;
                        background: white !important;
                        border: 0 none !important;
                        border-bottom: 1px solid #d7d7d7 !important;
                        td {
                            border: 0 none;
                        }
                    }
                }
            }
        }
    }
}

p-paginator {
    background: #ffffff !important;
    .ui-paginator-bottom {
        background-color: transparent !important;
        border: none !important;
        padding-top: 1rem !important;
        .ui-paginator-left-content {
            display: none;
        }
        .ui-paginator-right-content {
            display: none;
        }
        .ui-paginator-pages .ui-paginator-page.ui-state-active {
            background-color: #1a1a67 !important;
            color: #fff !important;
            border-radius: 4px;
        }
    }
}

.p-dialog {
    border: none !important;
    border-radius: 32px;
    overflow: hidden;
    // min-width: min(550px, 40%) !important;
    width: 550px !important;
    max-width: 90% !important;
    z-index: 1041 !important;
    background: #ffffff !important;
    .p-confirm-dialog-message {
        margin: 0 !important;
        word-break: break-word;
        color: rgba(0, 0, 0, 0.65) !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 24px !important;
    }
    .p-dialog-header {
        border-bottom: 0 !important;
        background: #fff !important;
        color: #333333 !important;
        padding: 28px !important;
        font-weight: 700 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        .p-dialog-title {
            font-family: 'JioBold' !important;
            font-size: 1.4rem !important;
            @media only screen and (max-width: 768px) {
                padding-right: 14px !important;
            }
        }
        .p-dialog-header-icons {
            position: absolute;
            top: 0;
            right: 0;
            padding: 24px;
            @media only screen and (max-width: 768px) {
                padding: 18px !important;
            }
        }
    }
    .p-dialog-content {
        padding: 0 32px !important;
        color: #191a1a !important;
        font-family: JioMedium !important;
        font-size: 1.4rem !important;
        i {
            display: none;
        }
    }
    .p-dialog-footer {
        border-top: 0 !important;
        background: #ffffff !important;
        color: #333333 !important;
        padding: 48px 32px 32px !important;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        button {
            // border: 1px solid #5A5A5A;
            height: 40px !important;
            margin: auto 8px !important;
            font-family: JioMedium !important;
            &.p-confirm-dialog-accept {
                order: 2;
            }
            &.with-bg {
                background: $primary-brand-color-light-theme !important;
                color: #fff !important;
                border: none !important;
            }
            &.cloud-video-accept {
                padding: 20px 40px !important;
            }
        }
    }
}

.sessions-confirmation-dialog {
    .p-dialog {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 10px #00000047;
        border: 1px solid #dbdbdb;
        border-radius: 8px;
        opacity: 1;
        .p-dialog-header {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            .p-dialog-title {
                color: #000;
                font-size: 20px !important;
                font-family: JioMedium !important;
                font-weight: normal;
            }
            .p-dialog-header-icon:enabled:hover {
                color: #406fd3;
            }
        }
        .p-dialog-content {
            color: #626262 !important;
            font-size: 18px !important;
        }
        .p-dialog-footer {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            button {
                width: 123px !important;
                height: 35px !important;
            }
        }
    }

    &.disable-session {
        .p-dialog {
            width: 455px !important;
        }
    }
}

.sponsors-confirmation-dialog {
    .p-dialog {
        .p-dialog-header {
            .p-dialog-title {
                color: #343537;
            }
        }
        .p-dialog-content {
            color: #52555e !important;
        }
    }
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border-bottom: 1px solid #c8c8c8 !important;
    border-width: 1px;
    padding: 0.571rem 0.857rem;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.571rem 0.857rem;
    border-bottom: 2px solid #000000 !important;
    border-width: 0 0 1px 0 !important;
    font-weight: 700;
    color: #545454;
    background: transparent !important;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 0 !important;
    border-width: 1px;
    padding: 0.571rem 0.857rem;
    word-break: break-all;
    border-bottom: 1px solid #e8ecf4 !important;
}
.p-datatable .p-datatable-tbody > tr {
    &:last-child {
        td {
            border-bottom: none !important;
        }
    }
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #007ad9;
    color: #333333 !important;
    outline: 0 !important;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #007ad9;
    color: #333333 !important;
    outline: 0 !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #333333 !important;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none !important;
    outline: 0 none;
}

.p-tooltip {
    z-index: 1032 !important;
    word-break: break-word;
    &.center {
        text-align: center;
    }
    &.attendee-call-tooltip {
        margin-left: -5px !important;
        .p-tooltip-text {
            font-size: 12px;
            line-height: 16px;
            font-family: JioMedium;
            background: #141414 !important;
            padding: 12px 16px;
            height: 40px;
            background: #ffffff;
            border-radius: 8px;
        }
        .p-tooltip-arrow {
            display: block;
            border-left-color: #141414 !important;
            border-width: 0.5em !important;
            right: -10px;
            top: 0;
            margin-top: 0.9rem !important;
        }
    }
    &.white-bg {
        .p-tooltip-arrow {
            display: none;
        }
        .p-tooltip-text {
            background: #ffffff !important;
            font-family: JioMedium;
            font-size: 1rem;
            color: #000000 !important;
            padding: 1rem !important;
            box-shadow: 0px 3px 6px #0000004d !important;
            border-radius: 8px;
        }
    }
    &.remove-file-icon {
        z-index: 1036 !important;
    }
    &.white-bg-arrow {
        .p-tooltip-arrow {
            display: block;
            border-top-color: #ffffff;
        }
        .p-tooltip-text {
            background: #ffffff !important;
            font-family: JioMedium;
            font-size: 1rem;
            color: #000000 !important;
            padding: 0.6rem !important;
            box-shadow: 0px 3px 6px #0000004d !important;
            border-radius: 8px;
        }
    }
    &.tooltip-revamp {
        .p-tooltip-text {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            font-family: JioMedium;
            background: #141414 !important;
            padding: 12px 16px;
            // height: 40px;
            color: #ffffff !important;
            border-radius: 8px;
        }
        .p-tooltip-arrow {
            content: url(~src/assets/images/primeng/tip_tooltip_arrow_revamp.svg);
            display: flex;
            right: 0;
            transform: translateX(-31%);
            bottom: -4px;
            width: 24px;
            height: 8px;
            border: none !important;
        }
        &.p-tooltip-top {
            transform: translateY(-6px);
        }
        &.p-tooltip-bottom {
            transform: translateY(6px);
            .p-tooltip-arrow {
                transform: translateX(-8px) translateY(-4px) rotate(180deg) !important;
            }
        }
        &.p-tooltip-left {
            transform: translateX(-8px);
            .p-tooltip-arrow {
                transform: translateX(12px) rotate(-90deg) !important;
            }
        }
        &.p-tooltip-right {
            transform: translateX(8px);
            .p-tooltip-arrow {
                transform: rotate(90deg) !important;
            }
        }
    }
}

.p-button {
    &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none !important;
    }
    .p-button-icon {
        display: none;
    }
}
.session-expansion-wrapper {
    .p-button {
        .p-button-icon {
            display: block;
        }
    }
}
//specific classes for tables in webinar-section
.webinar-table {
    border-collapse: separate !important;
    border-spacing: 0;
    border: 1px solid #e8ecf4;
    border-radius: 4px;
    thead {
        background: #f5f7fb 0% 0% no-repeat padding-box;
        border: 1px solid #e8ecf4;
        border-radius: 8px 8px 0px 0px;
        th {
            color: #000000 !important;
            font-family: JioBold !important;
            padding: 12px !important;
        }
    }
    tbody {
        tr {
            td {
                color: #52555e;
                font-family: 'JioMedium';
                padding: 10px !important;
            }
        }
    }
}
.sessions-list-event-table {
    border-collapse: separate !important;
    border-spacing: 0;
    border: 1px solid #e8ecf4;
    border-top: none;
    border-bottom: none;
    border-radius: 4px;
    thead {
        background: #ffffff 0% 0% no-repeat padding-box;
        height: 30px;
        th {
            color: #9a9ea7 !important;
            font-family: JioMedium !important;
            padding: 12px !important;
            font-size: 12px !important;
        }
    }
    tbody {
        tr {
            td {
                color: #52555e;
                font-family: 'JioMedium';
                padding: 12px !important;
                .p-button {
                    .p-button-icon {
                        display: block;
                        color: #000;
                    }
                }
            }
        }
    }
}
.p-datatable-scrollable-header-box {
    padding-right: 0px !important;
}
.invite-restricted-attendee-table {
    .blank-header {
        height: 50px;
    }
    .checkbox-header,
    .checkbox-body {
        width: 10%;
    }
    .name-header,
    .name-body {
        width: 25%;
    }
    .contact-info-header,
    .contact-info-body {
        width: 35%;
        &.multiselect {
            width: 65%;
        }
    }
    .edit-header,
    .edit-body {
        width: 10%;
    }

    .action-header,
    .action-body {
        width: 20%;
    }
}

.minWidthOverride {
    left: auto !important;
    right: 0px !important;
    min-width: max-content !important;
}

@media (max-width: 768px) {
    .p-dialog {
        max-width: 90% !important;
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #1a413b !important;
    background: $primary-brand-color-light-theme !important;
}

.pi-chevron-down {
    background: url(~src/assets/img/jioconf/misc/down_arrow_fill.svg) no-repeat center center;
    background-size: cover;
    width: 20px;
    height: 20px;
    &:before {
        content: '';
    }
}
.registration-container {
    .pi-chevron-down {
        background: url(~src/assets/img/down_arrow_outline.svg) no-repeat center center;
        width: 24px;
        height: 24px;
    }
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #979caa !important;
    // font-family: 'JioLight' !important;
    font-size: 16px !important;
}

.p-paginator {
    background: none !important;
    border: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $primary-brand-color-light-theme;
    color: #fff;
    border-radius: 50%;
}

.p-paginator-left-content,
.p-paginator-right-content {
    visibility: hidden;
}

.invite-attendee-paginator {
    .p-paginator {
        margin: 10px 0px;
        font-family: 'JioMedium';
        color: #343537;
        .p-paginator-left-content,
        .p-paginator-right-content {
            visibility: visible !important;
            margin-right: 0 !important;
        }
    }
}

.pi-sort-alt:before {
    content: url(~src/assets/img/jioconf/misc/sort.svg) !important;
}

.pi-sort-amount-up-alt {
    transform: rotate(180deg);
    &::before {
        content: url(~src/assets/img/jioconf/misc/sort_up.svg) !important;
    }
}

.pi-sort-amount-down:before {
    content: url(~src/assets/img/jioconf/misc/sort_down.svg) !important;
}

.attendee-networking-room-wrapper {
    // background: transparent linear-gradient(164deg, #080808 0%, #1a413b 100%) 0% 0% no-repeat padding-box;
    // box-shadow: 0px 6px 15px #2e2e2e41;
    // border-radius: 24px;
    .p-dialog-content {
        padding: 0px !important;
        border-radius: 24px;
    }
}

.networking-room-create-sidebar {
    min-width: 410px;
    .p-sidebar-header {
        .p-sidebar-close {
            display: none !important;
            width: 24px !important;
            height: 24px !important;
            cursor: pointer;
            position: absolute;
            right: 2.5rem;
            top: 4rem;
            padding: 0px !important;
            border-radius: 6px;
            display: flex;
            opacity: 0.8;
        }
    }
    .p-sidebar-content {
        flex: 1;
    }
    &.legacy-styling {
        background: #2b2b2b;
        width: 30% !important;
        max-width: unset !important;
        &.dark_background {
            background: #2b2b2b;
            display: flex;
        }
        .p-sidebar-close {
            display: flex !important;
            left: -2rem !important;
            top: 20px !important;
            right: 20px !important;
        }
    }
}

.networking-room-category-dropdown {
    width: 100%;
    height: 50px;
    align-items: center;
    border: 1px solid #c7ccd9;
    border-radius: 8px;
}

.p-sidebar-mask {
    z-index: 999997 !important;
}
.p-sidebar {
    z-index: 999998 !important;
}

.je-cal-week-view .cal-day-headers {
    padding-left: 0px;
}

.p-checkbox-revamp {
    * {
        outline: none !important;
        box-shadow: none !important;
        border-color: rgba(255, 255, 255, 0.78) !important;
    }
    .p-checkbox {
        .p-checkbox-box {
            background: transparent !important;
            &.p-highlight {
                border: none !important;
                background: #58dac5 !important;
            }
        }
    }
    .pi-check:before {
        color: #1a413b !important;
    }
}

.p-checkbox-legacy {
    * {
        outline: none !important;
        box-shadow: none !important;
    }
    .p-checkbox {
        .p-checkbox-box {
            background: transparent !important;
            &.p-highlight {
                border: none !important;
                background: #58dac5 !important;
            }
            &.p-focus {
                border-color: #58dac5 !important;
            }
        }
    }
}

.sidebar-revamp {
    background: #2b2b2b;
    padding: 24px;
    border: none;
}

.sortBy-polls-dropdown {
    .p-dropdown {
        border: none;
        background: transparent;
        cursor: pointer;

        .p-dropdown-label {
            padding-right: 1px !important;
        }

        &.p-focus {
            box-shadow: unset !important;
        }
        .selected-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #8ae5d6;
        }

        .pi-chevron-down {
            background: url(~src/assets/images/speaker-call-wrapper/p_dropdown_chevron_down_green.svg) no-repeat center
                center !important;
        }
    }
    .p-dropdown-panel {
        z-index: 1040 !important;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
        border-radius: 8px !important;
        overflow: hidden !important;
        border: none !important;
        margin-top: 10px;
        z-index: 1032;
        .p-dropdown-items-wrapper {
            max-height: unset !important;
            padding: 8px 0 !important;
            .p-dropdown-items {
                .p-dropdown-item {
                    width: 172px !important;
                    padding: 0px !important;
                    font-weight: 500;
                    &.p-highlight {
                        color: #08332c !important;
                        background: #1eccb0 !important;
                    }
                }
                .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
                    color: #1e7b74 !important;
                }
            }
        }
        .item-text {
            padding: 10px 20px;
            display: flex;
        }
    }
}

.polls-sortby-panel {
    z-index: 1040 !important;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    border: none !important;
    margin-top: 10px;
    z-index: 1032;
    .p-dropdown-items-wrapper {
        max-height: unset !important;
        padding: 8px 0 !important;
        .p-dropdown-items {
            .p-dropdown-item {
                width: 172px !important;
                padding: 0px !important;
                font-weight: 500;
                &.p-highlight {
                    color: #08332c !important;
                    background: #1eccb0 !important;
                }
            }
            .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
                color: #1e7b74 !important;
            }
        }
    }
    .item-text {
        padding: 10px 20px;
        display: flex;
    }
}

.image-generate-container {
    .p-dialog-mask {
        background: rgba(0, 0, 0, 0.28);
    }
    .p-dialog {
        box-shadow: 0px 4px 10px #00000047;
        border: 1px solid #dbdbdb !important;
        border-radius: 10px;
        padding: 0.8rem 0;
    }
    .p-dialog-header {
        display: flex;
        justify-content: flex-end;
        padding: 0 1rem !important;
        .p-dialog-header-icons {
            // display: none;
            color: #000;
        }
    }
}

.contest-post-overlay-mask {
    background: rgba(0, 0, 0, 0.5);
    .p-dialog {
        width: auto !important;
        border-radius: 10px;
        .p-dialog-header {
            padding: 0 !important;
            .p-dialog-header-icons {
                padding: 5px;
                .p-dialog-header-close-icon {
                    z-index: 1;
                }
                .p-dialog-header-close-icon::before {
                    content: url(~src/assets/images/close-media-popup.png);
                }
            }
        }
        .p-dialog-content {
            padding: 0 !important;
            height: 75vh;
            overflow: hidden;
            video,
            img {
                height: 75vh;
            }
        }
    }
    @media (max-width: 768px) {
        .p-dialog-content {
            height: 50vh !important;
            > div {
                height: 100%;
                display: flex;
                align-items: center;
            }
            video,
            img {
                object-fit: contain;
                height: auto;
                background: #777777;
            }
        }
    }
}
.contest-card {
    p-paginator {
        background: transparent !important;
    }
    .p-paginator {
        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
            color: #8ae5d6;
            &:hover {
                background: transparent !important;
                color: #8ae5d6 !important;
            }
        }
        .p-paginator-pages {
            .p-paginator-page {
                color: #348376;
                margin: 0 8px;
                border-radius: 50%;
                font-size: 16px;
                font-family: JioMedium;
                font-weight: 700;
                &.p-highlight {
                    color: #245a51;
                    background: #b4eee5;
                }
                &:hover {
                    background: #1a413b;
                    color: #8ae5d6;
                }
            }
        }
    }
}

.event-feed-dropdown {
    background-color: #fff !important;
}

.event-feed-dropdown-mobile {
    background-color: #fff !important;
    right: 0;
    left: unset !important;
}

.dark_theme {
    .title {
        color: #fff !important;
    }
    .section-header {
        color: #fff !important;
        border-bottom: 1px solid #5a5a5a !important;
    }
    label,
    .label {
        color: #ffffffcc !important;
    }
    .note,
    .note-1,
    .note-2 {
        color: #a1a1a1 !important;
    }
    input[type='text'],
    textarea {
        border: 1px solid #5a5a5a !important;
        color: #ffffffcc !important;
        background: transparent !important;
        &:hover {
            background-color: transparent !important;
        }
    }
    input[type='radio'] + span:before {
        box-shadow: 0 0 0 1px #58dac5 !important;
        border: 2.5px solid #2b2b2b !important;
    }
    input[type='radio']:checked + span:before {
        background: #58dac5 !important;
    }
    input:checked + .slider {
        background-color: #58dac5 !important;
    }
    input[type='checkbox'] {
        accent-color: #58dac5 !important;
    }

    //editor
    .text-editor-wrapper {
        background: transparent !important;
        .ql-toolbar.ql-snow {
            border-bottom: 1px solid #5a5a5a !important;
        }
    }
    .ql-editor {
        padding: 12px 0 !important;
        p {
            color: rgba(255, 255, 255, 1) !important;
        }
    }

    .ql-picker-label:before {
        color: rgba(255, 255, 255, 0.8);
    }
    .ql-snow {
        .ql-stroke,
        .ql-fill {
            stroke: rgba(255, 255, 255, 0.8);
        }
    }
    .ql-editor.ql-blank:before {
        color: #a1a1a1;
    }
    .recurring-container span {
        color: rgba(255, 255, 255, 0.8) !important;
    }

    //dropdown
    .p-dropdown {
        background: transparent !important;
        border: 1px solid #5a5a5a !important;
        .p-dropdown-label {
            color: #fff !important;
        }
        .pi-chevron-down {
            background: url(~src/assets/img/down_arrow_white.svg) no-repeat center center !important;
        }
    }
    .p-dropdown-revamp .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
        padding: 0.5rem 1.5rem !important;
    }

    //popup
    &.popup-component {
        .popup {
            background: #1a1a1a !important;
            .popup-header {
                .topic {
                    color: #ffffff !important;
                }
            }
            .tabview-custom {
                .p-tabview-nav-link {
                    background: transparent !important;
                    color: #ffffffcc !important;
                }
                li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
                    border-color: #fff !important;
                    color: #fff !important;
                    background-color: transparent !important;
                }
                .p-highlight {
                    .p-tabview-nav-link {
                        border-bottom: 3px solid #58dac5 !important;
                        color: #58dac5 !important;
                    }
                }

                .p-tabview-panels {
                    background: transparent !important;
                }
            }
        }
    }
    //tabview
    .p-tabview {
        .p-tabview-nav {
            background: transparent !important;
        }
    }
    .p-tabview-nav-link {
        background: transparent !important;
        color: #ffffffcc !important;
    }
    li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        border-color: #fff !important;
        color: #fff !important;
        background-color: transparent !important;
        border-radius: 0 !important;
    }
    .p-highlight {
        .p-tabview-nav-link {
            border-color: #58dac5 !important;
            color: #58dac5 !important;
        }
    }

    .p-tabview-panels {
        background: transparent !important;
    }

    //form
    .custom-form {
        input[type='checkbox'] ~ label:before {
            background-color: #2e2e2e !important;
        }
        input[type='radio'] + span:before {
            box-shadow: 0 0 0 1px #58dac5 !important;
            border: 2.5px solid #2b2b2b !important;
        }
        input[type='radio']:checked + span:before {
            background: #58dac5 !important;
        }
        input[type='text'],
        textarea {
            border: 1px solid #5a5a5a !important;
            color: #ffffffcc !important;
            background: transparent !important;
            &:hover {
                background-color: transparent !important;
            }
        }
    }

    //upload
    .upload-section {
        border: 1px dashed #5a5a5a !important;
        color: #ffffffcc !important;
        button {
            border: 1px solid #58dac5 !important;
            background-color: transparent !important;
            color: #58dac5 !important;
            &:hover {
                background-color: transparent !important;
                color: #58dac5 !important;
                border: 1px solid #58dac5 !important;
            }
        }
    }

    //excluding popups,dropdown-menus
    .popup-component,
    .dropdown-menu {
        .popup {
            background: #ffffff !important;
        }
        .p-dropdown {
            .p-dropdown-label {
                color: #000 !important;
            }
            .pi-chevron-down {
                background: url(~src/assets/img/jioconf/misc/down_arrow_fill.svg) no-repeat center center !important;
            }
        }
        label,
        .label {
            color: #000 !important;
        }
        input[type='text'],
        textarea {
            border: 1px solid #979caa !important;
            color: #000 !important;
        }
        input[type='radio'] + span:before {
            box-shadow: 0 0 0 1px #58dac5 !important;
            border: 2.5px solid #ffffff !important;
        }
        input[type='radio']:checked + span:before {
            background: #58dac5 !important;
        }
        .custom-form {
            input[type='checkbox'] ~ label:before {
                background-color: #fff !important;
            }
            input[type='text'],
            textarea {
                border: 1px solid #979caa !important;
                color: #000 !important;
            }
            input[type='radio'] + span:before {
                box-shadow: 0 0 0 1px #58dac5 !important;
                border: 2.5px solid #ffffff !important;
            }
            input[type='radio']:checked + span:before {
                background: #58dac5 !important;
            }
        }
        .ql-editor {
            padding: 12px 0 !important;
            p {
                color: #000 !important;
            }
        }
        .ql-picker-label:before {
            color: #000 !important;
        }
        .ql-snow {
            .ql-stroke,
            .ql-fill {
                stroke: #444;
            }
        }
    }
}
