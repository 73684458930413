@font-face {
    font-family: JioLight;
    src: url('/assets/fonts/jio/JioTypeW04-Light.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/jio/JioTypeW04-Light.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/jio/JioTypeW04-Light.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Light.woff') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Light.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 200;
}
@font-face {
    font-family: JioMedium;
    src: url('/assets/fonts/jio/JioTypeW04-Medium.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/jio/JioTypeW04-Medium.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/jio/JioTypeW04-Medium.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Medium.woff') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
}
@font-face {
    font-family: JioBold;
    src: url('/assets/fonts/jio/JioTypeW04-Bold.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/jio/JioTypeW04-Bold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/jio/JioTypeW04-Bold.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Bold.woff') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 600;
}
@font-face {
    font-family: JioBlack;
    src: url('/assets/fonts/jio/JioTypeW04-Black.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/jio/JioTypeW04-Black.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/jio/JioTypeW04-Black.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Black.woff') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/jio/JioTypeW04-Black.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 800;
}
@font-face {
    font-family: webBlack;
    src: url('/assets/fonts/website/JioType-Black_0.ttf'); /* IE9 Compat Modes */
    src: url('/assets/fonts/website/JioType-Black_0.ttf?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/website/JioType-Black_0.ttf') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/website/JioType-Black_0.ttf') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/website/JioType-Black_0.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 200;
}
@font-face {
    font-family: webBold;
    src: url('/assets/fonts/website/JioType-Bold_0.ttf'); /* IE9 Compat Modes */
    src: url('/assets/fonts/website/JioType-Bold_0.ttf?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/website/JioType-Bold_0.ttf') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/website/JJioType-Bold_0.ttf') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/website/JioType-Bold_0.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 600;
}

@font-face {
    font-family: webMedium;
    src: url('/assets/fonts/website/JioType-Medium_0.ttf'); /* IE9 Compat Modes */
    src: url('/assets/fonts/website/JioType-Medium_0.ttf?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/website/JioType-Medium_0.ttf') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/website/JioType-Medium_0.ttf') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/website/JioType-Medium_0.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
}
@font-face {
    font-family: webLight;
    src: url('/assets/fonts/website/JioType-Light_0.ttf'); /* IE9 Compat Modes */
    src: url('/assets/fonts/website/JioType-Light_0.ttf?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/website/JioType-Light_0.ttf') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/website/JioType-Light_0.ttf') format('woff'),
        /* Pretty Modern Browsers */ url('/assets/fonts/website/JioType-Light_0.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 200;
}
