@import 'variables';
@import 'mixins';
@import 'colors';

button {
    &.custom-button {
        outline: none !important;
        border: none !important;
        background-color: none !important;
        border-radius: 20px !important;
        height: 40px !important;
        padding: 0 24px !important;
        user-select: none;
        transition: background-color 0.3s ease;
        &.lg {
            font-size: 16px;
            line-height: 22px;
        }
        &.md {
            font-size: 14px;
            line-height: 20px;
            height: 32px !important;
            padding: 0 1rem !important;
        }
        &.sm {
            font-size: 12px !important;
            line-height: 16px !important;
            height: 26px !important;
            padding: 0 10px !important;
        }
        &.cornered {
            border-radius: 4px !important;
        }
        &.bg-primary {
            border: none !important;
            background-color: $primary-brand-color-light-theme !important;
            color: $primary-brand-text-color-light-theme !important;
            &:hover {
                // border: none !important;
                // background-color: $primary-brand-color-dark !important;
                // color: #fff !important;
                background-color: $primary-brand-color-light-theme-hover !important;
                color: $primary-brand-text-color-light-theme-hover !important;
            }
            &.disabled {
                border: none !important;
                // color: #979caa !important;
                color: $primary-brand-text-color-light-theme !important;
                // background: #c7ccd9 !important;
                pointer-events: none !important;
                // opacity: 1 !important;
                opacity: 0.3 !important;
                word-break: normal;
            }

            &.light-theme {
                background-color: $primary-brand-color-light-theme !important;
                color: $primary-brand-text-color-light-theme !important;
                // padding: 12px 16px !important;
                //width: auto;
                &:hover {
                    // border: none !important;
                    background-color: $primary-brand-color-light-theme-hover !important;
                    color: $primary-brand-text-color-light-theme-hover !important;
                }
                &.disabled {
                    opacity: 0.3 !important;
                }
            }
            &.dark-theme {
                background-color: $primary-brand-color-dark-theme !important;
                color: $primary-brand-text-color-dark-theme !important;
                // padding: 12px 16px !important;
                // width: auto;
                &:hover {
                    // border: none !important;
                    background-color: $primary-brand-color-dark-theme-hover !important;
                    color: $primary-brand-text-color-dark-theme-hover !important;
                }
                &.disabled {
                    opacity: 0.3 !important;
                }
            }
        }
        &.bg-secondary {
            border: 1px solid #e0e0e0 !important;
            background-color: transparent !important;
            color: #1e7b74 !important;
            width: auto;
            &:hover {
                // border: none !important;
                // background-color: $primary-brand-color !important;
                // color: #fff !important;
                border: 1px solid $primary-130 !important;
                color: $primary-130 !important;
            }
            &.light-theme {
                border: 1px solid $secondary-120 !important;
                color: $primary-130 !important;
                // padding: 12px 16px !important;
                width: auto;
                &:hover {
                    border: 1px solid $primary-130 !important;
                    background-color: #fff !important;
                    color: $primary-130 !important;
                }
                &.disabled {
                    opacity: 0.3 !important;
                }
            }

            &.dark-theme {
                border: 1px solid #5a5a5a !important;
                color: #8ae5d6 !important;
                //  padding: 12px 16px !important;
                width: auto;
                &:hover {
                    border: 1px solid #8ae5d6 !important;
                }
                &.disabled {
                    opacity: 0.3 !important;
                }
            }
        }
        &.bg-orange {
            background: $secondary-brand-color;
            border: none;
            outline: none;
            border-radius: 24px;
            color: #fff;
            &:hover {
                background: $secondary-brand-color-dark;
            }
        }
        &.bg-gradient {
            background: transparent linear-gradient(134deg, #406fd3 0%, #1c326a 100%) 0% 0% no-repeat padding-box;
        }
        &.disabled {
            border: none !important;
            color: #979caa !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
    }
    &.bg-orange {
        background: $secondary-brand-color;
        border: none;
        outline: none;
        border-radius: 24px;
        color: #fff;
        &:hover {
            background: $secondary-brand-color-dark;
        }
    }
    &.bg-gradient {
        background: transparent linear-gradient(134deg, #406fd3 0%, #1c326a 100%) 0% 0% no-repeat padding-box;
    }
    &.disabled {
        border: none !important;
        color: #979caa !important;
        background: #c7ccd9 !important;
        pointer-events: none !important;
        opacity: 1 !important;
    }
    &.bg-login {
        border: none !important;
        background-color: $downy !important;
        color: $te-papa-green !important;
        // &:hover {
        //   // border: none !important;
        //   background-color: $primary-brand-color-dark !important;
        //   color: #fff !important;
        // }
        &.disabled {
            border: none !important;
            color: $licorice !important;
            background: #37534e !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
        &.disabled-primary {
            border: none !important;
            color: #979caa !important;
            background: #c7ccd9 !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
    }
    &.bg-login-invert {
        border: 1px solid #e0e0e0;
        background-color: unset !important;
        // color: #1E7B74 !important;
        color: #8ae5d6 !important;
        // &:hover {
        //   // border: none !important;
        //   background-color: $primary-brand-color-dark !important;
        //   color: #fff !important;
        // }
        &.disabled {
            border: none !important;
            color: $licorice !important;
            background: #37534e !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
        &.disabled-primary {
            border: none !important;
            color: #979caa !important;
            background: #c7ccd9 !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
        &.no-border {
            border: none !important;
        }
    }
    &.btn-mint-grey {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        font-family: JioMedium;
        border: 1px solid #b5b5b5;
        border-radius: 80px;
        height: 28px;
        padding: 4px 12px;
        margin: 4px;
        // background-color: $downy !important;
        // color: $te-papa-green !important;
        // &:hover {
        //   // border: none !important;
        //   background-color: $primary-brand-color-dark !important;
        //   color: #fff !important;
        // }
        &.disabled {
            border: none !important;
            color: $licorice !important;
            background: #37534e !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
        &.disabled-primary {
            border: none !important;
            color: #979caa !important;
            background: #c7ccd9 !important;
            pointer-events: none !important;
            opacity: 1 !important;
        }
    }
}

@media (min-width: 240px) {
    button {
        &.custom-button {
            &.delete-reject-btn {
                padding: 0px 12px !important;
            }
            &.delete-accept-btn {
                padding: 0px 12px !important;
            }
        }
    }
}

// Xtra Small devices (feature phones, 240px and up)
@media (min-width: 240px) {
    button {
        &.custom-button {
            &.lg {
                font-size: 16px;
                line-height: 22px;
            }
            &.md {
                font-size: 14px;
                line-height: 20px;
            }
            &.sm {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

// Xtra Large devices (xtra large desktops, 1800 and up)
@media (min-width: 1800px) {
    button {
        &.custom-button {
            &.lg {
                font-size: 18px;
                line-height: 24px;
            }
            &.md {
                font-size: 16px;
                line-height: 22px;
            }
            &.sm {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

button {
    &.custom-button-revamp {
        outline: none !important;
        border: none !important;
        background-color: none !important;
        background: transparent !important;
        border-radius: 1000px !important;
        height: 40px !important;
        padding: 0 24px !important;
        user-select: none !important;
        transition: background-color 0.3s ease;
        font-size: 16px !important;
        margin: auto 12px;
        width: 100%;
        &.disabled-primary {
            border: none !important;
            color: #1a413b !important;
            background: #8ae5d6;
            pointer-events: none !important;
            opacity: 0.3 !important;
        }
        &.btn-primary {
            background: #58dac5 !important;
            color: #1a413b !important;
            &:hover {
                background: #8ae5d6 !important;
            }
            &.disabled {
                opacity: 0.3 !important;
                background: #8ae5d6 !important;
            }
        }
        &.btn-secondary {
            border: 1px solid #5a5a5a !important;
            color: #8ae5d6 !important;
            &:hover {
                border: 1px solid #8ae5d6 !important;
            }
            &.disabled {
                opacity: 0.3 !important;
                // background: #8AE5D6 !important;
            }
        }
        &.btn-tertiary {
            color: #8ae5d6 !important;
            &:hover {
                background: #1a413b !important;
                // border: 1px solid #8AE5D6 !important;
            }
            &.disabled {
                opacity: 0.3 !important;
            }
        }
        &.btn-confirm-primary {
            background: #1eccb0 !important;
            color: #0e5c4f !important;
            &:hover {
                background: #2bc3aa !important;
            }
            &.disabled {
                opacity: 0.3 !important;
                background: #8ae5d6 !important;
            }
        }
        &.btn-confirm-secondary {
            border: 1px solid #e0e0e0 !important;
            color: #1e7b74 !important;
            &:hover {
                border: 1px solid #1e7b74 !important;
            }
            &.disabled {
                opacity: 0.3 !important;
                // background: #8AE5D6 !important;
            }
        }
        &.sm {
            height: fit-content !important;
            line-height: 24px !important;
            padding: 4px 16px !important;
        }
        &.xsm {
            height: fit-content !important;
            line-height: 18px !important;
            font-size: 14px !important;
            padding: 4px 12px !important;
        }
        &.xxsm {
            height: fit-content !important;
            line-height: 16px !important;
            font-size: 12px !important;
            padding: 8px 12px !important;
        }
        &.md {
            height: fit-content !important;
            line-height: 16px !important;
            font-size: 12px !important;
            padding: 4px 16px !important;
        }
        &.lg {
            height: fit-content !important;
            padding: 12px 16px !important;
        }
        &.fit {
            width: fit-content !important;
        }
        &.quad-pad {
            padding: 4px 48px !important;
            margin: 0 !important;
        }
        &.font-16-24-700 {
            font-weight: 700 !important;
            font-size: 16px !important;
            line-height: 24px !important;
        }
        &.btn-confirm-primary-2,
        &.light-mint-inverse {
            color: #08332c !important;
            background: #1eccb0 !important;
            &:hover {
                background: #2bc3aa !important;
            }
            &.disabled {
                opacity: 0.3 !important;
                background: #8ae5d6 !important;
            }
        }
    }
}

.w-unset {
    width: unset !important;
}

#createCustomButton {
    @include flex-container(center, center);
    gap: 8px;
    width: fit-content;
}
