@import 'variables';

@mixin flex-container($align, $justify, $direction: row) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin box-shadow($color: #bbbbbb) {
    box-shadow: 0px 3px 5px $color;
}

@mixin box-shadow($size: sm, $color: $neutral-100) {
    box-shadow: 0px 4px 8px $color;
    @if $size == xs {
        box-shadow: 0px 2px 4px $color;
    } @else if ($size == sm) {
        box-shadow: 0px 4px 8px $color;
    } @else if ($size == md) {
        box-shadow: 0px 8px 16px $color;
    } @else if ($size == lg) {
        box-shadow: 0px 12px 24px $color;
    } @else if ($size == xl) {
        box-shadow: 0px 16px 32px $color;
    } @else if ($size == xxl) {
        box-shadow: 0px 20px 40px $color;
    }
}

@mixin top-box-shadow($color: #bbbbbb) {
    box-shadow: -10px 0px 5px $color;
}

@mixin font($family, $size) {
    font-size: calc($size/$primary-font-size-value) + rem;
    @if $family == light {
        font-family: JioLight;
    } @else if ($family == medium) {
        font-family: JioMedium;
    } @else if ($family == bold) {
        font-family: JioBold;
    } @else if ($family == black) {
        font-family: JioBlack;
    }
}

@mixin width($width) {
    width: $width / $primary-font-size-value + rem;
}

@mixin height($height) {
    height: $height / $primary-font-size-value + rem;
}

@mixin min-width($width) {
    min-width: $width / $primary-font-size-value + rem;
}

@mixin min-height($height) {
    min-height: $height / $primary-font-size-value + rem;
}

@mixin max-width($width) {
    max-width: $width / $primary-font-size-value + rem;
}

@mixin max-height($height) {
    max-height: $height / $primary-font-size-value + rem;
}

@mixin margin($margin, $direction: all) {
    @if $direction == all {
        margin: $margin / $primary-font-size-value + rem;
    } @else if ($direction == top) {
        margin-top: $margin / $primary-font-size-value + rem;
    } @else if ($direction == bottom) {
        margin-bottom: $margin / $primary-font-size-value + rem;
    } @else if ($direction == left) {
        margin-left: $margin / $primary-font-size-value + rem;
    } @else if ($direction == right) {
        margin-right: $margin / $primary-font-size-value + rem;
    } @else if ($direction == leftRight) {
        margin-left: $margin / $primary-font-size-value + rem;
        margin-right: $margin / $primary-font-size-value + rem;
    } @else if ($direction == topBottom) {
        margin-top: $margin / $primary-font-size-value + rem;
        margin-bottom: $margin / $primary-font-size-value + rem;
    }
}

@mixin padding($padding, $direction: all) {
    @if $direction == all {
        padding: $padding / $primary-font-size-value + rem;
    } @else if ($direction == top) {
        padding-top: $padding / $primary-font-size-value + rem;
    } @else if ($direction == bottom) {
        padding-bottom: $padding / $primary-font-size-value + rem;
    } @else if ($direction == left) {
        padding-left: $padding / $primary-font-size-value + rem;
    } @else if ($direction == right) {
        padding-right: $padding / $primary-font-size-value + rem;
    } @else if ($direction == leftRight) {
        padding-left: $padding / $primary-font-size-value + rem;
        padding-right: $padding / $primary-font-size-value + rem;
    } @else if ($direction == topBottom) {
        padding-top: $padding / $primary-font-size-value + rem;
        padding-bottom: $padding / $primary-font-size-value + rem;
    }
}

@mixin flex-container($align, $justify, $direction: row) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin text($size, $color, $weight, $line-height, $family: JioMedium) {
    font-size: $size !important;
    color: $color !important;
    font-weight: $weight !important;
    line-height: $line-height !important;
    font-family: $family !important;
}

@mixin sofiaProFont($family, $size, $style: normal) {
    font-size: calc($size/$primary-font-size-value) + rem;
    font-family: 'sofia-pro';
    font-style: $style;
    @if $family == light {
        font-weight: 400;
    } @else if ($family == medium) {
        font-weight: 500;
    } @else if ($family == semibold) {
        font-weight: 600;
    } @else if ($family == bold) {
        font-weight: 700;
    }
}
