@import 'mixins';

.popup-component {
    .bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1031;
    }
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 2rem;
        min-width: 700px;
        max-width: 96%;
        max-height: 90%;
        z-index: 1034; // Highest priority
        overflow-y: auto;
        overflow-x: hidden;
        &.scrollable-content {
            display: flex;
            flex-direction: column;
            padding: 0;
            .popup-header {
                padding: 2rem;
                margin-bottom: 0;
            }
            .popup-body {
                flex-grow: 1;
                overflow-y: auto;
                padding: 0 2rem;
            }
            .popup-footer {
                padding: 2rem;
            }
        }
        &.inline {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            z-index: 0;
        }
        .popup-header {
            @include flex-container(flex-start, space-between);
            margin-bottom: 1rem;
            h3 {
                // @include font(medium, 32);
                color: #52555e;
                display: inline-block;
                width: 90%;
            }
            .close {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
        .popup-body {
        }
        .popup-footer {
        }
    }
}

.popup-simple {
    width: 40% !important;
    min-width: fit-content !important;
    max-width: 500px !important;
    box-shadow: 0px 4px 10px #00000047;
    .title {
        font-size: 24px;
        color: $neutral-90;
    }
    .popup-body {
        font-size: 18px;
        color: #52555e;
        input[type='number'] {
            width: 30% !important;
        }
    }
    button {
        height: 35px !important;
    }
}

@media screen and (max-width: 1079px) /** lg **/ {
    .popup-component {
        .popup {
            // min-width: 96%;
        }
    }
}

@media screen and (max-width: 720px) /** lg **/ {
    .popup-component {
        .popup {
            min-width: 96%;
        }
    }
}
