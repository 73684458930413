@import 'variables';
@import 'mixins';

.form-control {
    color: $primary-text-color !important;
    border: none !important;
    border-bottom: 1px solid #d1d1d1 !important;
    border-radius: 0 !important;
    font-family: JioMedium;
    outline: none !important;
    -webkit-appearance: none;

    input {
        height: 44px;
        background: $neutral-0 0% 0% no-repeat padding-box;
        border: 1px solid #babace !important;
        border-radius: 8px !important;
    }

    &.with-border {
        border: 1px solid #d1d1d1 !important;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.75;
    }
}

.form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

.btn {
    &.primary {
        background: $primary-brand-color;
        border: 1px solid $primary-brand-color;
        color: #fff !important;
        border-radius: 4px;
        height: 40px;
    }
    &.dropdown-toggle {
        outline: none !important;
        border-radius: 1000px;
        padding: 4px !important;
        &.focus {
            box-shadow: none !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}

body .ui-button {
    color: gray !important;
    border: 1px solid transparent !important;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
    background-color: $primary-brand-color !important;
}

body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #848484 !important;
}

body .ui-table .ui-sortable-column:focus {
    box-shadow: none !important;
}

#msg-area {
    a {
        text-decoration: underline !important;
        color: $primary-brand-color !important;
    }
}

.content {
    .toast-container {
        position: absolute !important;
    }
}

.toast-container .ngx-toastr {
    //min-height: 3.5rem !important;
    word-break: break-word;
}

.inside-call-toast {
    display: inherit;
    opacity: 1;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 20px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); */
    pointer-events: auto;
    background: #414141 !important;
    color: #bebebe !important;
    text-align: center;
    color: #bebebe !important;
    text-align: center;
    border: #414141 !important;
}

.inside-call-chat-toast {
    display: inherit;
    opacity: 1;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 20px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); */
    pointer-events: auto;
    background: #ffffff !important;
    color: #212121 !important;
    text-align: center;
    color: #000000 !important;
    text-align: center;
    border: #373737 !important;
    bottom: -14rem !important;
}

.inside-call-hand-raise {
    display: inherit;
    opacity: 1;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 15px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); */
    pointer-events: auto;
    background: #ffffff !important;
    color: #212121 !important;
    text-align: center;
    color: #000000 !important;
    text-align: center;
    border: #373737 !important;
    bottom: -14rem !important;
    background: url(/assets/img/raise_hand_in_toaster.svg) #fff no-repeat 15px !important;
    padding-left: 36px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    // border-color: $primary-brand-color !important;
    background-color: $primary-brand-color !important;
}

.custom-control-input:indeterminate ~ .custom-control-label:before {
    border-color: #000000 !important;
    background-color: #ffffff !important;
}

.end-call-popup .content .footer {
    margin-top: 0 !important;
    padding: 0 20px 20px 20px !important;
    border-top: 0 !important;
}

.end-call-popup {
    border-bottom: 0 !important;
    .content {
        border-radius: 3px !important;
        .header {
            border-bottom: 0 !important;
            color: #333 !important;
            padding: 1em !important;
            font-weight: 700 !important;
        }
    }
}

.end-call-popup .content .footer .options .option {
    background: #fff !important;
    // border: 1px solid #fff !important;
    margin-left: 5px;
    color: $primary-brand-color-dark !important;
    font-family: JioMedium !important;
    outline: none !important;
}

.audiencemode-toast {
    width: 390px !important;
    // color: #315CBB !important;
    font-family: JioMedium, sans-serif !important;
    // background-color: #D7ECFB !important;
    .speaker {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url('/assets/img/audiencemode-icons/makespeaker.svg');
        height: 19px;
        width: 20px;
        margin-top: 8px;
    }

    .audience {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url('/assets/images/speaker-call-wrapper/demote_participant_icon.svg');
        height: 19px;
        width: 20px;
        margin-top: 8px;
    }

    .got-it-btn {
        text-align: center;
        margin-top: 20px;
        margin-left: 235px;
        padding: 5px 15px 5px 15px;
        width: 104px;
        height: 35px;
        border: 1px solid #315cbb;
        border-radius: 20px;
        opacity: 1;
    }
    // .toast-close-button {
    //   color: #315CBB !important;
    // }

    .infoimage {
        top: 12px;
        right: 12px;

        .toast-info {
            background-image: url(/assets/img/audiencemode-icons/informationicon.svg) !important;
        }
    }
}

.kebab-trigger {
    border-radius: 250px;
    height: fit-content;
    &:hover,
    &.kebab-trigger[aria-expanded='true'] {
        background: #245a51;
    }
}

.dropdown-menu-revamp {
    // Can remove important tag if required
    background: #f5f5f5 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    padding: 8px 0 !important;
    .dropdown-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 20px;
        &:hover {
            background: #e0e0e0 !important;
            color: #1e7b74 !important;
        }
        &:active {
            background: #58dac5 !important;
            color: #08332c !important;
        }
    }
}

.p-dropdown-revamp {
    .p-dropdown-panel {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
        border-radius: 8px !important;
        overflow: hidden !important;
        border: none !important;
        margin-top: 10px;
        .p-dropdown-items-wrapper {
            max-height: unset !important;
            padding: 8px 0 !important;
            .p-dropdown-items {
                .p-dropdown-item {
                    padding: 16px !important;
                    .item-title {
                        font-weight: 700 !important;
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }
                    .item-description {
                        font-weight: 500 !important;
                        font-size: 12px !important;
                        line-height: 16px !important;
                        white-space: pre-line;
                    }
                    &.p-highlight {
                        color: #1a413b !important;
                        background: #58dac5 !important;
                    }
                }
            }
        }
    }
}

.promote-action-btn {
    padding: 4px;
    &.showPromote {
        content: url(~src/assets/images/event-call-options/promote_attendee_default_icon.svg);
        &:hover {
            content: url(~src/assets/images/event-call-options/promote_attendee_icon.svg);
        }
    }
    content: url(~src/assets/images/event-call-options/participant_demote_icon_down.svg); // demote
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.call-options-header {
    @include flex-container(center, space-between);
    @include text(16px, #f5f5f5, 700, 24px);
    margin: 20px 16px 8px 16px;
    // padding: 0 8px 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #5a5a5a;
    .title {
        flex: 1;
        display: flex;
    }
    .close-icon {
        background: url(~src/assets/img/close_dark_theme.svg) no-repeat center center;
        display: flex;
        justify-content: center;
        height: 24px;
        width: 24px;
    }
}

.background-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.je-nav-tab {
    @include flex-container(center, center, column);
    &:hover .nav-tab-bottom-border {
        visibility: visible;
    }
    &.active .nav-tab-bottom-border {
        visibility: visible;
        background-color: #58dac5;
    }
    .nav-tab-bottom-border {
        background-color: #245a51;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        bottom: 2px;
        left: 0;
        display: block;
        visibility: hidden;
    }
    // margin-bottom: -4px;
    @media (max-width: 768px) {
        border-bottom: 0px;
        margin-bottom: 0px;
    }
}

.close-rooms-dialog-popup {
    .custom-form {
        .form-item {
            margin: auto !important;
            width: fit-content !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }
    }
    .p-dialog .p-dialog-footer {
        padding: 12px 32px 32px !important;
    }
    .p-inputtext {
        border: 1px solid #08332c !important;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none !important;
        width: 112px;
        height: 56px;
        text-align: center;
        @include text(18px, #141414, 700, 24px);
    }
    .p-inputnumber-buttons-horizontal {
        gap: 12px;
    }
    .input-strip-wrapper {
        background: transparent;
        border: none;
        border-radius: 1000px !important;
        width: 42px;
        padding: 12px;
        height: 42px;
        margin: auto;
        &.input-chevron-right {
            content: url(~src/assets/images/networking-rooms/plus_icon_mint.svg);
            &:hover {
                background: #1a413b;
                content: url(~src/assets/images/networking-rooms/plus_icon_hover_state.svg);
            }
        }
        &.input-chevron-left {
            content: url(~src/assets/images/networking-rooms/minus_icon_mint_default.svg);
            &:hover {
                background: #1a413b;
                content: url(~src/assets/images/networking-rooms/minus_icon_mint_hover.svg);
            }
        }
        &:active {
            background: #245a51 !important;
        }
    }
    .action-description {
        @include text(16px, rgba(0, 0, 0, 0.65), 500, 24px);
    }
    .title {
        @include text(24px, #141414, 700, 32px);
    }
    .p-dialog-header {
        padding-bottom: 0px;
    }
}

.je-red-asterisk {
    color: #e02934;
}

.call-option-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        font-family: JioBold;
        margin: auto 12px 8px;
        border-bottom: 1px solid #5a5a5a;
        padding: 10px;
        .title {
            text-align: center;
            font-family: JioBold;
        }
    }
    .body {
        overflow-y: auto;
        bottom: 0px;
        position: relative;
        width: 100%;
        height: 100%;
        .comment {
            border: 1px solid #d5d5d5;
            border-radius: 6px;
            opacity: 1;
            font-family: JioMedium;
        }
    }
    .no-ticker-info-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.78);
        justify-content: center;
        span {
            padding: 16px 80px;
            text-align: center;
        }
    }
}

:root {
    --moveable-color: red !important;
}
