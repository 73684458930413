// Primary colours
$primary-0: #f6fbff !default;
$primary-5: #d8e9fd !default;
$primary-80: #406fd3 !default;
$primary-90: #315cbb !default;
$primary-100: #214796 !default;
$primary-110: #1c326a !default;
$primary-120: #1eccb0 !default;
$primary-130: #1e7b74 !default;
$primary-140: #08332c !default;
$primary-150: #1a413b !default;
$primary-160: #58dac5 !default;
$primary-170: #8ae5d6 !default;

// Secondary colours
$secondary-80: #ff6c00 !default;
$secondary-90: #f7714d !default;
$secondary-100: #ef5425 !default;
$secondary-110: #cd3400 !default;

$secondary-120: #e0e0e0 !default;
$secondary-130: #5a5a5a !default;

// Neutrals colours
$neutral-0: #ffffff !default;
$neutral-10: #f5f6fb !default;
$neutral-20: #e7eaf4 !default;
$neutral-40: #c4c9d9 !default;
$neutral-50: #afb4c5 !default;
$neutral-60: #979caa !default;
$neutral-70: #767b87 !default;
$neutral-80: #52555e !default;
$neutral-90: #343537 !default;
$neutral-100: #000000 !default;

// Semantic colours

// Positive
$positive-10: #dcfce3;
$positive-100: #1a873c;
$positive-110: #0b7133;

// Warning
$warning-10: #fff3ea;
$warning-100: #de6421;
$warning-110: #c04503;

// Negative
$negative-10: #ffdbdc;
$negative-100: #c71720;
$negative-110: #96181c;

// Informational
$info-10: #daf0ff;
$info-100: #1eccb0;
$info-110: #0061c3;

$text-color-primary: #324776;
$text-color-29: #151515;
$bg-neutral-7: #0f827e;
$text-color-8: #5b6c93;
$text-color-4: #fc7873;

.color-white {
    color: #ffffff !important;
}

.color-grey-100 {
    color: #141414 !important;
}
