// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// );

// https://zeroheight.com/1435d76f5/p/57df70-layout/b/820540
$grid-breakpoints: (
    xs: 0,
    sm: 360px,
    md: 720px,
    lg: 1080px,
    xl: 1260px,
    xxl: 1440px
);

@include media-breakpoint-down(xs) {
    $grid-gutter-width: 8px;
}
@include media-breakpoint-down(sm) {
    $grid-gutter-width: 12px;
    html {
        font-size: 12px !important;
        $primary-font-size-value: 12;
    }
}
@include media-breakpoint-down(md) {
    $grid-gutter-width: 16px;
    html {
        font-size: 13px !important;
        $primary-font-size-value: 13;
    }
}
@include media-breakpoint-down(lg) {
    $grid-gutter-width: 20px;
}
@include media-breakpoint-down(xl) {
    $grid-gutter-width: 24px;
}

// Xtra Small devices (feature phones, 240px and up)
// @media (min-width: 240px) { ... }

// Small devices (smart phones, 360px and up)
// @media (min-width: 360px) { ... }

// Medium devices (tablets, 720px and up)
// @media (min-width: 720px) { ... }

// Large devices (large tablets, 1080 and up)
// @media (min-width: 1080px) { ... }

// Xtra Large devices (desktops, 1260 and up)
// @media (min-width: 1260px) { ... }

// Xtra Large devices (large desktops, 1440 and up)
// @media (min-width: 1440px) {
//   font-size: 14px !important;
//   $primary-font-size-value: 14;
// }

// Xtra Large devices (xtra large desktops, 1800 and up)
// @media (min-width: 1800px) { ... }
