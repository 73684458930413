@import 'variables';
@import 'mixins';

.text-list-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: JioMedium;
    color: #141414 !important;
}

.white-12-500 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #f5f5f5;
}
.black-14-500-065 {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: rgba(0, 0, 0, 0.65) !important;
    font-family: JioMedium !important;
}
.grey-16-700-24-078 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px !important;
    font-family: JioMedium !important;
    color: rgba(255, 255, 255, 0.78) !important;
}

.grey-14-500-20-078 {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: rgba(255, 255, 255, 0.78) !important;
}

.text-500-12-16 {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.white-18-700-24 {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #ffffff !important;
}
