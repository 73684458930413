@import 'variables';

* {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}
html {
    font-size: $primary-font-size;
    font-family: $primary-font-family;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
}
body {
    font-family: $primary-font-family;
    color: $primary-text-color;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: $primary-text-color;
    &.scroll-lock {
        overflow: hidden !important;
    }
    &.whiteboard {
        overflow: inherit;
        width: 2200px;
        height: 2200px;
    }
}
a:not([href]):not([tabindex]) {
    color: $primary-brand-color;
    &:hover,
    &:focus {
        color: $primary-link-color;
    }
}
button {
    outline: none;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
iframe {
    -webkit-overflow-scrolling: touch;
}
a {
    color: $primary-brand-color;
    &:hover {
        text-decoration: none;
        color: $primary-brand-color;
    }
    &.light {
        font-family: JioLight;
    }
    &.underline {
        border-bottom: 1px solid;
    }
    &.disabled {
        color: #979caa !important;
        pointer-events: none;
        cursor: initial;
    }
}
.pointer {
    cursor: pointer;
}
.clearfix {
    clear: both;
}
.full {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.text-error {
    color: $error-color;
}

.text-success {
    color: $success-color;
}
::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: #50505080;
    box-shadow: inset 0 0 8px #707070;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: transparent;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb {
    background-color: #3e3b3b;
    border: 2px solid #3e3b3b;
    border-radius: 4px;
}
// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: #f5f5f5;
//     border-radius: 4px;
// }
// ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//     background-color: #fff;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: #aaa;
//     border: 2px solid #aaa;
//     border-radius: 4px;
// }
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none;
}
h1 {
    font-size: 60px;
    font-family: JioMedium;
    line-height: 72px;
}
h2 {
    font-size: 50px;
    font-family: JioMedium;
    line-height: 60px;
}
h3 {
    font-size: 42px;
    font-family: JioMedium;
    line-height: 51px;
}
h4 {
    font-size: 32px;
    font-family: JioMedium;
    line-height: 45px;
}
h5 {
    font-size: 22px;
    font-family: JioMedium;
    line-height: 30px;
}
h6 {
    font-size: 20px;
    font-family: JioMedium;
    line-height: 28px;
}

/* Margins */
.ml-7 {
    margin-left: 5rem;
}
.ml-10 {
    margin-left: 10rem;
}
.mr-7 {
    margin-bottom: 5rem !important;
}
.mr-9 {
    margin-bottom: 7rem !important;
}

/* Paddings */
.pl-7 {
    padding-left: 5rem;
}
.pl-10 {
    padding-left: 10rem;
}
.pr-7 {
    padding-right: 5rem !important;
}
.pr-9 {
    padding-right: 7rem !important;
}

// Small devices (smart phones, 360px and up)
@media (min-width: 200px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 36px;
        line-height: 44px;
    }
    h2 {
        font-size: 28px;
        line-height: 34px;
    }
    h3 {
        font-size: 24px;
        line-height: 29px;
    }
    h4 {
        font-size: 20px;
        line-height: 28px;
    }
    h5 {
        font-size: 16px;
        line-height: 22px;
    }
    h6 {
        font-size: 14px;
        line-height: 20px;
    }
}

// Medium devices (tablets, 720px and up)
@media (min-width: 720px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 42px;
        line-height: 51px;
    }
    h2 {
        font-size: 32px;
        line-height: 39px;
    }
    h3 {
        font-size: 26px;
        line-height: 32px;
    }
    h4 {
        font-size: 22px;
        line-height: 30px;
    }
    h5 {
        font-size: 16px;
        line-height: 22px;
    }
    h6 {
        font-size: 14px;
        line-height: 20px;
    }
}

// Xtra Large devices (desktops, 1260 and up)
@media (min-width: 1260px) {
    body {
        font-size: 16px;
    }
    h1 {
        font-size: 48px;
        line-height: 58px;
    }
    h2 {
        font-size: 38px;
        line-height: 46px;
    }
    h3 {
        font-size: 30px;
        line-height: 36px;
    }
    h4 {
        font-size: 24px;
        line-height: 34px;
    }
    h5 {
        font-size: 20px;
        line-height: 28px;
    }
    h6 {
        font-size: 16px;
        line-height: 22px;
    }
}

// Xtra Large devices (large desktops, 1440 and up)
@media (min-width: 1440px) {
    h1 {
        font-size: 54px;
        line-height: 65px;
    }
    h2 {
        font-size: 44px;
        line-height: 53px;
    }
    h3 {
        font-size: 36px;
        line-height: 44px;
    }
    h4 {
        font-size: 28px;
        line-height: 39px;
    }
    h5 {
        font-size: 20px;
        line-height: 28px;
    }
    h6 {
        font-size: 18px;
        line-height: 23px;
    }
}

.opacity-1 {
    opacity: 1;
}

// Xtra Large devices (xtra large desktops, 1800 and up)
@media (min-width: 1800px) {
    h1 {
        font-size: 60px;
        line-height: 72px;
    }
    h2 {
        font-size: 50px;
        line-height: 60px;
    }
    h3 {
        font-size: 42px;
        line-height: 51px;
    }
    h4 {
        font-size: 32px;
        line-height: 45px;
    }
    h5 {
        font-size: 22px;
        line-height: 30px;
    }
    h6 {
        font-size: 20px;
        line-height: 28px;
    }
}
.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.jiotype-bold-heavy-metal-16px {
    color: $heavy-metal;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 700;
}
.jiotype-black-te-papa-green-16px {
    color: $te-papa-green;
    font-family: $font-family-webtype-black;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 900;
}
.jiotype-bold-te-papa-green-16px {
    color: $te-papa-green;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 700;
}
.jiotype-bold-white-16px {
    color: $white;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 700;
}
.jiotype-medium-white-14px-2 {
    color: $white-2;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: 500;
}
.jiotype-medium-white-14px {
    color: $white;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: 500;
}
.jiotype-black-white-64px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: $font-size-xxxl;
    font-style: normal;
    font-weight: 900;
}
.jiotype-black-white-32px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
}
.jiotype-black-white-40px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: $font-size-xxl;
    font-style: normal;
    font-weight: 900;
}
.jiotype-bold-white-18px {
    color: $white;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-l;
    font-style: normal;
    font-weight: 700;
}
.jiotype-black-white-24px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: 900;
}
.jiotype-light-white-72px {
    color: $white;
    font-family: $font-family-webtype-light;
    font-size: 72px;
    font-style: normal;
    font-weight: 300;
}
.jiotype-light-white-24px {
    color: $white;
    font-family: $font-family-webtype-light;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
}
.jiotype-black-white-144px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: 144px;
    font-style: normal;
    font-weight: 900;
}
.jiotype-medium-white-16px-2 {
    color: $white;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 500;
}
.jiotype-black-chicago-40px {
    color: $chicago;
    font-family: $font-family-webtype-black;
    font-size: $font-size-xxl;
    font-style: normal;
    font-weight: 900;
}
.jiotype-black-te-papa-green-64px {
    color: $te-papa-green;
    font-family: $font-family-webtype-black;
    font-size: $font-size-xxxl;
    font-style: normal;
    font-weight: 900;
}
.jiotype-medium-white-ice-24px {
    color: $white-ice;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: 500;
}
.jiotype-medium-white-16px {
    color: $white-2;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 500;
}
.jiotype-black-white-16px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: $font-size-m;
    font-style: normal;
    font-weight: 900;
}
.jiotype-medium-white-12px {
    color: $white-2;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: 500;
}
.jiotype-black-white-88px {
    color: $white;
    font-family: $font-family-webtype-black;
    font-size: 88px;
    font-style: normal;
    font-weight: 900;
}
.jiotype-medium-white-18px {
    color: $white-2;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-l;
    font-style: normal;
    font-weight: 500;
}
.jiotype-medium-white-18px-2 {
    color: $white;
    font-family: $font-family-webtype-medium;
    font-size: $font-size-l;
    font-style: normal;
    font-weight: 500;
}
.jiotype-bold-white-12px {
    color: $white;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: 700;
}
.jiotype-bold-white-24px-2 {
    color: $white-2;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: 700;
}
.jiotype-bold-star-dust-14px {
    color: $star-dust;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: 700;
}
.jiotype-bold-downy-24px {
    color: $downy;
    font-family: $font-family-webtype-bold;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: 700;
}
.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.in-call-toast-right {
    top: 70px;
    right: 45px;
    max-width: 375px;
    pointer-events: visible;
    .custom-toast {
        border-radius: 8px;
        font-weight: initial;
        background-position: 15px 18px !important;
        padding: 16px 16px 16px 48px;
        .toast-title {
            @include font(medium, 16);
            font-weight: initial;
            display: inline-block;
        }
        .toast-message {
            @include font(light, 14);
        }
    }
    .info {
        background-color: #d7ecfb !important;
        color: #0061c3;
        background: url(~src/assets/img/blue-info.svg) no-repeat;
        .toast-close-button {
            color: #0061c3;
        }
    }
    .success {
        background-color: #dcfce3 !important;
        color: #0b7133;
        background: url(~src/assets/img/green-tick-functional-filled.svg) no-repeat;
    }
}

.downloadlink-info {
    @extend .in-call-toast-right;
    max-width: max-content;
}
.hide {
    display: none;
}

.mobile-bottom-dropdown {
    position: fixed;
    max-height: 50vh;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: #fff;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    .text-end {
        text-align: end;
    }
    .label {
        color: #141414;
        @include font(bold, 18);
        line-height: 24px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
    .mobile-bottom-dropdown {
        max-height: 100vh;
    }
}
.tab {
    .curved-border-bottom {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &.active {
        .curved-border-bottom {
            background-color: #315cbb;
            &.revamp {
                background-color: #58dac5;
            }
        }
    }
    &:hover {
        &.active {
            .curved-border-bottom.revamp {
                background-color: #58dac5;
            }
        }
        .curved-border-bottom.revamp {
            background-color: #245a51;
        }
    }
}
button.support-btn:focus {
    outline: auto !important;
}
.cursor-pointer {
    cursor: pointer;
}

.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(5px);
    display: block;
    z-index: 0;
}

.loader-wrapper {
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 50px;
}

.pi-times {
    &::before {
        content: url(~src/assets/img/close_icon_green.svg);
        cursor: pointer;
        position: relative;
        top: 0rem !important;
        width: 24px !important;
        height: 24px !important;
    }
}

select:focus > option:checked {
    background: $primary-brand-color !important;
}

.ql-snow .ql-editor a {
    color: $primary-brand-color;
}
.toolbar-options {
    .ql-stroke,
    .ql-fill,
    .ql-stroke-miter {
        @extend .fill-primary-color;
    }
}

.fill-primary-color {
    color: $primary-brand-color !important;
    stroke: $primary-brand-color !important;
}

.ql-formats {
    > button:hover,
    > select:hover,
    > span:hover {
        @extend .toolbar-options;
        .ql-picker-options {
            .ql-stroke {
                stroke: #444 !important;
            }

            .ql-picker-item {
                &:hover {
                    .ql-stroke {
                        stroke: $primary-brand-color !important;
                    }
                }
            }
        }
        .ql-picker-label {
            @extend .fill-primary-color;
            &:before {
                @extend .fill-primary-color;
            }
        }
    }

    .ql-active {
        color: $primary-brand-color !important;
        @extend .toolbar-options;
    }

    .ql-picker-options {
        .ql-stroke {
            stroke: #444 !important;
        }
    }
    .ql-picker-label {
        .ql-fill {
            opacity: 0.3;
        }
    }
}

.ql-snow.ql-toolbar .ql-picker-item:hover {
    @extend .fill-primary-color;
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    @extend .fill-primary-color;
}
