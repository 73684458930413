@import 'variables';
@import 'mixins';

.custom-form {
    label {
        @include font(bold, 14);
        color: #52555e;
        display: inline-block;
        margin-bottom: 0;
        position: relative;
        color: #767b87;
        font-family: 'JioMedium';
    }
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='date'],
    input[type='password'],
    input[type='tel'] {
        height: 48px;
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        font-size: 1.14rem;
        color: #000 !important;
        outline: none !important;
        padding: 0 1rem !important;
        border: 1px solid #afb4c5 !important;
        border-radius: 8px !important;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition-delay: 9999s;
            transition-property: background-color, color;
        }
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        &.is-invalid {
            border: 2px solid #c71720 !important;
        }
        &.invalid {
            border: 2px solid #c71720 !important;
            box-shadow: none;
        }
    }
    input[type='radio'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        ~ label {
            cursor: pointer;
        }
        + span {
            display: block;
            &::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                vertical-align: -0.25em;
                border-radius: 1em;
                border: 2.5px solid #fff;
                box-shadow: 0 0 0 1px $primary-brand-color-dark-theme;
                margin-right: 0.75em;
                transition: 0.5s ease all;
            }
        }
        &:checked {
            + span {
                &::before {
                    background: $primary-brand-color-dark-theme;
                }
            }
        }
        &:focus {
            + span {
                &::after {
                    font-size: 1.5em;
                    line-height: 1;
                    vertical-align: -0.125em;
                }
            }
        }
    }
    input[type='checkbox'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        z-index: -1;
        opacity: 0;
        ~ label {
            padding: 0rem 1.8rem !important;
            &::before {
                border-radius: 0.25rem;
                position: absolute;
                top: 0.25rem;
                left: 0;
                display: block;
                width: 1rem;
                height: 1rem;
                pointer-events: none;
                content: '';
                background-color: #fff;
                border: #adb5bd solid 1px;
                transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }
            &::after {
                position: absolute;
                top: 0.25rem;
                left: 0;
                display: block;
                width: 1rem;
                height: 1rem;
                content: '';
                background: no-repeat 50%/50% 50%;
            }
        }
        &:checked {
            ~ label {
                padding: 0.1rem 1.8rem !important;
                &::before {
                    background-color: $primary-brand-color !important;
                    color: #fff;
                    display: none;
                }
                &::after {
                    background-image: url(~src/assets/img/checked-box.svg);
                    width: 2rem;
                    height: 2rem;
                    margin: -6px 0px !important;
                    position: absolute;
                    left: -5px !important;
                }
            }
        }
    }
    .text-error {
        font-size: 1rem;
        color: $negative-100;
        margin-top: 0.2rem;
        display: inline-block;
    }
    .help {
        @include font(light, 14);
        color: #72747b;
    }
}

::-webkit-input-placeholder {
    // @include font(light, 16);
    color: #b5b5b5 !important;
}

::-moz-placeholder {
    @include font(light, 16);
    color: #b5b5b5 !important;
}

:-ms-input-placeholder {
    @include font(light, 16);
    color: #b5b5b5 !important;
}

:-moz-placeholder {
    @include font(light, 16);
    color: #b5b5b5 !important;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input {
        &:checked {
            + {
                .slider {
                    background-color: $primary-brand-color;

                    &:before {
                        transform: translateX(21px);
                    }
                }
            }
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border-radius: 34px;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 0px;
            background-color: #f4f4f4;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}

.je-global-search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    // width: 90%;
    margin: 0;
    padding: 6px 16px 6px 10px;
    border: 1px solid #5a5a5a;
    border-radius: 30px;
    max-width: 100%;
    .je-search-icon {
        .default-image {
            display: block;
        }
        .active-image {
            display: none;
        }
    }
    &:focus-within:not(.chat-speakers-dropdown) {
        border: 1px solid #58dac5;
        .je-search-icon {
            .default-image {
                display: none;
            }
            .active-image {
                display: block;
            }
        }
    }
    img {
        margin: 0 !important;
    }
    .je-search-icon {
        margin: 0 !important;
        img {
            margin: 0 !important;
        }
    }
    input {
        &.je-attendees-search {
            border: none !important;
            outline: none !important;
            width: 100%;
            background: transparent;
            outline: none !important;
            color: #ffffff !important;
            padding: 0 8px;
            &::placeholder {
                color: #b5b5b5 !important;
            }
        }
        font-family: JioMedium;
    }
    .je-close-search-icon {
        img {
            margin: 0 !important;
        }
        position: absolute;
        bottom: 9px;
        right: 10px;
        opacity: 1;
    }
    &.type-2 {
        background: #5a5a5a;
        height: 44px;
        padding: 12px 16px;
    }
    &.chat-speakers-dropdown {
        border-color: #b5b5b5;
        .je-attendees-search {
            @include text(14px, #141414, 500, 20px);
        }
    }
}

.je-form-input-label-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
}

.je-form-input {
    border: 0.5px solid #ffffff !important;
    border-radius: 8px !important;
    height: 40px !important;
    padding: 10px 16px !important;
    @include text(14px, #ffffff, 500, 20px);
    background: transparent;
    outline: none;
    &::-webkit-input-placeholder {
        color: #a1a1a1 !important;
    }
    &.focus-in {
        padding: 10px 32px 10px 16px !important;
    }
}

.je-form-label {
    font-size: 12px !important;
    color: rgba(255, 255, 255, 0.78) !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    font-family: JioMedium !important;
}

.je-form-limit-count {
    display: inherit;
    justify-content: flex-end;
    color: #767b87;
    font-size: 0.7142857143rem;
    position: absolute;
    bottom: -1rem;
    right: 0;
    font-family: JioMedium;
    width: 100%;
}

.je-file-drop-container {
    border: 1px dashed #ffffff !important;
    border-radius: 24px !important;
    @include text(16px, #8ae5d6, 700, 24px);
}
