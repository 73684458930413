@keyframes pulse {
    0% {
        transform: scale(0.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}
