@import 'variables';

.toast-container {
    top: 70px;
    right: 60px;
    pointer-events: visible;
    width: fit-content;
    @media screen and (max-width: 768px) {
        //height: 32px;
        right: 5px;
        top: 10px;
        padding-left: 5px;
    }
    @media screen and (orientation: landscape) and (max-device-width: 992px) {
        top: 10px;
        right: 10px;
    }
    .ngx-toastr {
        width: auto !important;
        @media screen and (max-width: 768px) {
            //min-height: 32px !important;
            border-radius: 50px !important;
        }
        > div {
            display: flex;
            margin: auto 24px auto 0px;
        }
        background-color: rgba(255, 255, 255, 0.78) !important;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16)) !important;
        border-radius: 16px !important;

        .toast-close-button {
            right: 12px;
            top: calc(50% - 2px);
            transform: translateY(-50%);
            position: absolute;
            font-size: 25px;
            font-family: jioLight;
        }

        .toast-message {
            @media screen and (max-width: 768px) {
                display: inline;
            }
        }
    }
    .toast-success {
        background-color: rgba(255, 255, 255, 0.78) !important;
        backdrop-filter: blur(12px);
        border-radius: 8px;
        // padding: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141414 !important;
        background-position: 15px 16px !important;
        background: url(~src/assets/images/toast_success_icon.svg) no-repeat;
        box-shadow: none !important;
        padding: 16px 24px 16px 48px !important;
        min-width: 150px;
        @media screen and (max-width: 768px) {
            background-position: 16px !important;
            padding: 4px 16px 4px 48px !important;
            //height: 32px !important;
        }
        .toast-close-button {
            color: #1a413b !important;
            text-shadow: none !important;
            @media screen and (max-width: 768px) {
                padding-top: 2px;
            }
        }
    }
    .toast-info {
        background-color: rgba(255, 255, 255, 0.78) !important;
        backdrop-filter: blur(12px);
        border-radius: 8px;
        // padding: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141414 !important;
        background-position: 15px 16px !important;
        background: url(~src/assets/images/toast_info_icon.svg) no-repeat;
        box-shadow: none !important;
        padding: 16px 24px 16px 48px !important;
        min-width: 150px;
        @media screen and (max-width: 768px) {
            background-position: 16px !important;
            padding: 4px 16px 4px 48px !important;
            //height: 32px !important;
        }
        .toast-close-button {
            color: #1a413b !important;
            text-shadow: none !important;
            @media screen and (max-width: 768px) {
                padding-top: 2px;
            }
        }
    }
    .toast-warning {
        background-color: rgba(255, 255, 255, 0.78) !important;
        backdrop-filter: blur(12px);
        border-radius: 8px;
        // padding: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141414 !important;
        background-position: 15px 16px !important;
        background: url(~src/assets/images/toast_warn_icon.svg) no-repeat;
        box-shadow: none !important;
        padding: 16px 24px 16px 48px !important;
        min-width: 150px;
        @media screen and (max-width: 768px) {
            background-position: 16px !important;
            padding: 4px 16px 4px 48px !important;
            margin-left: 6px;
        }
        .toast-close-button {
            color: #1a413b !important;
            text-shadow: none !important;
        }
    }
    .toast-error {
        background-color: rgba(255, 255, 255, 0.78) !important;
        backdrop-filter: blur(12px);
        border-radius: 8px;
        // padding: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #141414 !important;
        background-position: 15px 16px !important;
        background: url(~src/assets/images/toast_error_icon.svg) no-repeat;
        box-shadow: none !important;
        padding: 16px 24px 16px 48px !important;
        min-width: 150px;
        @media screen and (max-width: 768px) {
            background-position: 16px !important;
            padding: 4px 16px 4px 48px !important;
            margin-left: 6px;
        }

        .toast-close-button {
            color: #1a413b !important;
            text-shadow: none !important;
        }
    }
}

.custom-toast-width {
    width: 490px;
}

.call-controls-custom-margin {
    margin-right: 24px !important;
}
